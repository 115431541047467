import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import PopularSearch from "../PopularSearch/PopularSearch";
import PopularSearchArea from "../PopularSearch/PopularSearchArea";
import PopularSearchResults from "../PopularSearch/PopularSearchResults";
import PopularSearchDetails from "../PopularSearch/PopularSearchDetails";
import "./Footer.scss";
import GetLink from "../functions/GetLink";
import parse from "html-react-parser";
import CookieConsent from "../CookieConsent/CookieConsent";
import $ from "jquery";
import { Helmet } from "react-helmet";
import { AreaGuideDetail } from "../../queries/common_use_query";
import AppointmentBanner from "../AppointmentBanner/AppointmentBanner";
import ThePropertyOmbudsmanLogo from "../../images/property_ombudsman.png";
import ApprovedCodeLogo from "../../images/approved_code.png";
import useLocation from "../../hooks/useLocation";
import { useScript } from "../../hooks/useScript";
import YomdelChatScript from "./YomdelChatScript"
//const { YomdelChatScript } = require("@starberry/react-chat-widgets/Yomdel/Chat")

const Footer = (props) => {
  const data = useStaticQuery(graphql`
    query GetOfficesFooter {
      glstrapi {
        offices {
          id
          Name
          Office_Address
          Phone
          Email
          URL
        }

        globalConfig {
          Footer {
            Footer_Menus {
              Menu_External_Link
              Menu_Label
              id
              Menu_Link {
                Primary_URL
                Secondary_URL
                Label
                id
              }
            }
            Insta_Link
            LinkedIn_Link
            Twitter_Link
            FB_Link
            id
          }
        }
      }
    }
  `);
  let { pathname, href } = useLocation();
  const offices = data?.glstrapi?.offices;
  const footer_links = data?.glstrapi?.globalConfig?.Footer?.Footer_Menus;
  const footer_data = data?.glstrapi?.globalConfig?.Footer;

  const formattedOfficeAddress = offices?.map((office) =>
    office.Office_Address?.replaceAll(", ", "<br />")
  );
  const { loading: loading, error: error, data: areaGuidedata } = AreaGuideDetail(props.postcode);

  const shareurl = typeof window !== "undefined" ? window.location.href : "";
  let year = new Date().getFullYear();

  const trackerGTM = (event) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "Phone number click",
      formType: event + " - " + shareurl,
      formId: "Phone number click",
      formName: "Phone number click",
      formLabel: "Phone number click",
    });
  };

  const showBugherd = process.env.GATSBY_SHOW_BUGHERD;

  const [renderComponent, setRenderComponent] = useState(false);

  // let scriptsrc = `https://clients.yomdel.com/tools/chat_script.js?url=${
  //   typeof document !== "undefined" && document.location.href
  // }`;

  // const status = useScript(scriptsrc, {
  //   removeOnUnmount: true,
  //   shouldPreventLoad: !renderComponent || pathname === "/property-valuation/",
  // });

  // useEffect(() => {
  //   const element1 = document.getElementById("chat-widget-container");
  //   const element2 = document.getElementById("livechat-eye-catcher");
  //   if (element1) {
  //     element1.style.display = pathname === "/property-valuation/" ? "none" : "block";
  //   }
  //   if (element2) {
  //     element2.style.display = pathname === "/property-valuation/" ? "none" : "block";
  //   }
  // }, [pathname, status, href]);


  useEffect(() => {
    const handleRender = () => {
      setRenderComponent(true);
    };
    if (!renderComponent) {
      window.addEventListener("mousemove", handleRender);
      window.addEventListener("touchmove", handleRender);
      window.addEventListener("keypress", handleRender);
    }
    return () => {
      window.removeEventListener("mousemove", handleRender);
      window.removeEventListener("touchmove", handleRender);
      window.removeEventListener("keypress", handleRender);
    };
  }, [renderComponent]);

  return (
    <>
      {!props.searchResults && (
        <Container>
          <AppointmentBanner />
        </Container>
      )}
      <div className="footer-wrapper">
        {showBugherd == "true" ? (
          <Helmet>
            <script
              type="text/javascript"
              src="https://www.bugherd.com/sidebarv2.js?apikey=ky9zw4odscbpqlnsaxgbiw"
              async="true"
            ></script>
          </Helmet>
        ) : (
          ""
        )}
        <Container className="footer-container">
          {offices.map((office, i) => {
            return (
              <>
                {(office.URL != "property-management") &&
                  <div className="footer-section">
                    <Link
                      to={`/contact-us/`}
                      state={{
                        officeid: office.id,
                        officeemail: office.Email,
                        officename: office.Name,
                      }}
                      className="office-name hover-border-white"
                    >
                      {office.Name}
                    </Link>
                    <p className="office-address">{parse(formattedOfficeAddress[i])}</p>
                    <div className="office-contact">
                      <a
                        href={`tel:${office.Phone}`}
                        className="phone hover-border-white"
                        onClick={() => trackerGTM(office.Phone)}
                      >
                        {office.Phone}
                      </a>
                      <Link
                        to={`/contact-us/`}
                        state={{
                          officeid: office.id,
                          officeemail: office.Email,
                          officename: office.Name,
                        }}
                        className="email hover-border-white"
                      >
                        Email Office
                      </Link>
                    </div>
                  </div>
                }
              </>
            );
          })}
          <div className="footer-section footer-links-section">
            <div className="icons">
              <a href={footer_data.Twitter_Link} target="_blank" className="icon-twitter"></a>
              <a href={footer_data.FB_Link} target="_blank" className="icon-fb"></a>
              <a href={footer_data.Insta_Link} target="_blank" className="icon-instagram"></a>
              <a href={footer_data.LinkedIn_Link} target="_blank" className="icon-linkedin"></a>
              {/* <a href="javascript:;" target="_blank" className="icon-tiktok"></a> */}
              <a
                href="https://www.youtube.com/@davidastburys4874"
                target="_blank"
                className="icon-youtube"
              ></a>
            </div>
            <div className="footer-links">
              <p className="links">
                {footer_links.map((linkItem, i) => {
                  return (
                    <>
                      <GetLink
                        link={linkItem.Menu_Link}
                        label={linkItem.Menu_Label}
                        className="hover-border-white"
                      />
                      {i < footer_links.length - 1 ? "  |  " : ""}
                    </>
                  );
                })}
              </p>
              <p className="links">
                <a
                  href="https://ggfx-davidastburys.s3.eu-west-2.amazonaws.com/i.prod/Property_Ombudsman_cert_pdf_pagespeed_ce_M7g9d_Pa_Q34_21876823f5.pdf"
                  target="_blank"
                  className="hover-border-white"
                >
                  The Property Ombudsman
                </a>
                {"  |  "}
                <a
                  href="https://ggfx-davidastburys.s3.eu-west-2.amazonaws.com/i.prod/Propertymark_CMP_Main_Scheme_Certificate_2_899b2c64a9.pdf"
                  target="_blank"
                  className="hover-border-white"
                >
                  Client Money Protection Scheme
                </a>
                {"  |  "}
                <Link to="/complaints-procedure/" className="hover-border-white">
                  Complaints Procedure
                </Link>
                {"  |  "}
                <Link to="/landlord-fees/" className="hover-border-white">
                  Landlord fees
                </Link>
                {"  |  "}
                <a
                  href="https://ggfx-davidastburys.s3.eu-west-2.amazonaws.com/i.prod/Tenant_fees_Assured_Shorthold_Tenancies_V3_4_pdf_47ed6033ee.png"
                  target="_blank"
                  className="hover-border-white"
                >
                  Tenant fees
                </a>
                {"  |  "}
                <Link to="/sellers-fees/" className="hover-border-white">
                  Sellers fees
                </Link>
                {"  |  "}
                <a
                  href="javascript:void(0);"
                  id="open_preferences_center"
                  className="hover-border-white"
                >
                  Update Cookies Preferences
                </a>
                {"  |  "}
                <a
                  href="https://www.propertymark.co.uk/professional-standards/rules.html"
                  target="_blank"
                  className="hover-border-white"
                >
                  Propertymark Conduct & Membership Rules
                </a>
              </p>
              <p className="copyright">Copyright {year} David Astburys Estate Agents.</p>
              <div className="logo-section">
                <a
                  href="https://ggfx-davidastburys.s3.eu-west-2.amazonaws.com/i.prod/Property_Ombudsman_cert_pdf_pagespeed_ce_M7g9d_Pa_Q34_21876823f5.pdf"
                  target="_blank"
                >
                  <img src={ThePropertyOmbudsmanLogo} alt="ThePropertyOmbudsmanLogo" />
                </a>
                <a>
                  <img src={ApprovedCodeLogo} alt="ApprovedCodeLogo" />
                </a>
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <p className="company-extra-info">
            <p>
              Company registration number: <strong>&nbsp;10474483</strong>
              &nbsp;|&nbsp; Company registered address:{" "}
              <strong>&nbsp;15 Park Road, Crouch End, N8 8TE</strong>
            </p>
          </p>
        </Container>
        <Container className="disclaimer-wrapper d-block d-lg-flex">
          <p className="disclaimer-section">
            *The stats on the website have been collected by David Astburys from data analysed over
            the last 24 months
          </p>
          <p className="site-by">
            Site by <a href="https://starberry.tv/" className="icon-sb" target="_blank"></a>
          </p>
        </Container>
        <Container className="divider-container">
          <div className="horizontal-divider"></div>
        </Container>
        {props.popularsearch === "pagedetails" ? (
          <PopularSearchArea area={props.area} />
        ) : props.popularsearch === "results" ? (
          <PopularSearchResults searchParams={props.searchParams} />
        ) : props.popularsearch === "propertydetails" ? (
          <PopularSearchDetails
            area={props.area}
            searchaction={props.searchaction}
            areaguides={areaGuidedata?.areaguides[0]}
          />
        ) : (
          <PopularSearch popularsearch={props.popularsearch} />
        )}

        <CookieConsent />
      </div>
      <YomdelChatScript />
    </>
  );
};

export default Footer;
